import { sendUserNotificationIdReq } from '@/modules/notifications/api'
import { useAccountStore } from '@/store'
import { useEffect } from 'react'
import OneSignal from 'react-onesignal'

const appId = import.meta.env.VITE_ONESIGNAL_APP_ID

export const useOnesignal = () => {
    const { account } = useAccountStore()

    const initOnesignal = async () => {
        await OneSignal.init({
            appId,
        })
        await OneSignal.login(account?.id)
    }

    const sendId = async () => {
        await sendUserNotificationIdReq({
            notificationUserId: 'pc',
            deviceUuid: 'pc',
        })
    }

    useEffect(() => {
        OneSignal.User.PushSubscription.addEventListener('change', sendId)
        return () =>
            OneSignal.User.PushSubscription.removeEventListener(
                'change',
                sendId,
            )
    }, [])

    return { initOnesignal }
}
