const urlApi = {
    stage: {
        API_URL: 'https://api.safeguido.com/',
        SOCKET_URL: 'https://api.safeguido.com/',
    },
    prod: {
        API_URL: 'https://api.safetravy.com/',
        SOCKET_URL: 'https://api.safetravy.com/',
    },
}

export const config = {
    ...urlApi['stage'],
}
